exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-continuous-integration-js": () => import("./../../../src/pages/blog/ContinuousIntegration.js" /* webpackChunkName: "component---src-pages-blog-continuous-integration-js" */),
  "component---src-pages-blog-developer-tools-js": () => import("./../../../src/pages/blog/DeveloperTools.js" /* webpackChunkName: "component---src-pages-blog-developer-tools-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/Work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

